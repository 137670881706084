
import { SurveyQuestion } from "@/gql"
import { Component, Emit, Prop, Vue } from "vue-property-decorator"

@Component
export default class SurveySingleChoiceRadio extends Vue {
  @Prop({ required: false }) assignmentType?: string
  @Prop() readonly question!: SurveyQuestion
  @Prop() readonly value!: any

  @Emit("input")
  onInput(value: any) {
    return value
  }

  get firstOption() {
    return this.question.options[0]
  }

  get lastOption() {
    return this.question.options[this.question.options.length - 1]
  }
}
