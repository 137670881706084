
import { Component, Prop, Vue, Emit } from "vue-property-decorator"
import OutlinedTextField from "./OutlinedTextField.vue"
import { ScaleOptionType } from "../surveys/QuestionList.vue"

@Component({
  components: {
    OutlinedTextField,
  },
})
export default class ScaleInput extends Vue {
  @Prop() readonly options?: string

  @Prop() readonly value!: any

  @Prop() readonly editable!: boolean

  @Prop({ default: false }) readonly verticalLayout!: boolean

  get vertical() {
    return this.layout === "vertical"
  }

  @Prop({ default: false })
  disabled!: boolean

  @Emit("input")
  onInput(value: any) {
    return value
  }

  layout = "horizontal"
  minLabelValue = ""
  maxLabelValue = ""
  editingAllLabels = false

  form: { [label: number]: string } = {}

  get midLabelsCount() {
    if (!this.editingAllLabels) return 0
    return this.range.length - 2
  }

  get parsedOptions(): ScaleOptionType {
    return JSON.parse(this.options || "{}")
  }

  get min(): number {
    return this.parsedOptions ? this.parsedOptions["min"] || 1 : 1
  }

  get minLabel(): string {
    return this.parsedOptions ? this.parsedOptions["minLabel"] || "Not at all" : "Not at all"
  }

  get max(): number {
    return this.parsedOptions ? this.parsedOptions["max"] || 5 : 5
  }

  get maxLabel(): string {
    return this.parsedOptions ? this.parsedOptions["maxLabel"] || "Very much so" : "Very much so"
  }

  get range() {
    let arr = []
    for (let i = this.min; i <= this.max; i += 1) {
      arr.push(i)
    }

    return arr
  }

  getAssistiveText(n: number) {
    if (n === this.min) {
      return this.minLabel
    } else if (n === this.max) {
      return this.maxLabel
    }

    return this.parsedOptions.labels?.[n] || ""
  }

  onLayoutChange() {
    this.layout = this.layout === "horizontal" ? "vertical" : "horizontal"
    this.$emit("layoutChange", this.layout)
  }

  onMinLabelChange(value: string) {
    this.$emit("minLabelChange", value)
  }

  onMaxLabelChange(value: string) {
    this.$emit("maxLabelChange", value)
  }

  onLabelChange(index: number, value: string) {
    this.$emit("labelChange", { index, value })
  }

  onAddLabels() {
    this.editingAllLabels = true
    this.$emit("addLabels")
  }

  mounted() {
    this.minLabelValue = this.minLabel
    this.maxLabelValue = this.maxLabel
    this.layout = this.parsedOptions ? this.parsedOptions["layout"] || "horizontal" : "horizontal"
    this.editingAllLabels = this.parsedOptions ? this.parsedOptions["customLabels"] || false : false
    this.form = this.parsedOptions.labels || {}
  }
}
