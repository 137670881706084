
import {
  RespondToSurveyMutationMutation,
  RespondToSurveyMutation,
  Survey,
  FetchSurveyQuery,
  RespondToSurveyMutationMutationVariables,
  SurveyQuestion,
} from "@/gql"
import { Vue, Component } from "vue-property-decorator"
import ScaleInput from "@/components/fields/ScaleInput.vue"
import SurveySingleChoiceRadio from "@/components/fields/SurveySingleChoiceRadio.vue"
import Analytics from "@/data/ahead_hr_assessment_analytics.json"

@Component({
  components: {
    ScaleInput,
    SurveySingleChoiceRadio,
  },
})
export default class CompleteSurvey extends Vue {
  readonly surveyQuery = FetchSurveyQuery
  surveyCompleted = false
  submitFailed = false
  loading = false
  loadingError = false
  networkError = false
  submitting = false
  survey: Survey | null = null
  currentQuestionIndex = 0
  answers: Record<string, any> = {}

  showAnalytics = false
  surveyAverageScore: null | undefined | number = null
  anaylticsData = Analytics

  getAnalyticsByKey(key: number) {
    const keyMapping: Record<number, string> = {
      1: "1-2",
      2: "1-2",
      3: "3",
      4: "4-5",
      5: "4-5",
    }

    return this.anaylticsData.find((x) => x.key === keyMapping[key])
  }

  async mounted() {
    this.fetchSurveyAssigment()
  }

  async fetchSurveyAssigment() {
    try {
      this.loading = true

      // Fetch surveys from server
      const result = await this.$apollo.query({
        query: this.surveyQuery,
        variables: {
          id: this.$route.params.id,
        },
      })

      if (result.data && result.data.publicSurveys) {
        this.loading = false

        if (result.data.publicSurveys.length && result.data.publicSurveys[0]) {
          this.survey = result.data.publicSurveys[0]
        }
      }

      if (result && result.errors) {
        this.loadingError = true
      }
    } catch (error) {
      this.networkError = true
    } finally {
      this.loading = false
    }
  }

  goBackToSurvey() {
    this.$router.go(0)
  }

  getParsedData(data: any) {
    try {
      return JSON.parse(data)
    } catch (error) {
      return data
    }
  }

  get questions() {
    return this.survey?.questions ?? []
  }

  ORPHAN_QUESTION_ID = "ORPHAN_QUESTION_ID"

  get GroupedQuestions() {
    return this.questions?.reduce((acc, curr) => {
      const id = curr.surveySection?.id ?? this.ORPHAN_QUESTION_ID
      if (acc[id]) {
        acc[id].push(curr)
      } else {
        acc[id] = [curr]
      }
      return acc
    }, {} as Record<string, SurveyQuestion[]>)
  }

  getSectionDetails(id: string) {
    return this.survey?.surveySections.find((q) => q.id === id)
  }

  get answeredQuestions() {
    return this.setupAnswer.filter((answer: any) => {
      return ![undefined, null, ""].includes(answer) && Array.isArray(answer)
        ? answer.length > 0
        : true
    })
  }

  get setupAnswer() {
    let answerHash: any[] = []

    for (const [key, value] of Object.entries(this.answers)) {
      answerHash.push({ question_id: key, choice: value })
    }

    return answerHash
  }

  get isAllQuestionsAnswered() {
    return this.answeredQuestions.length === this.questions?.length
  }

  async submitSurvey() {
    this.submitting = true

    const result = await this.mutate<
      RespondToSurveyMutationMutation,
      RespondToSurveyMutationMutationVariables
    >({
      mutation: RespondToSurveyMutation,
      variables: {
        surveyId: this.survey!.id,
        response: { data: JSON.stringify(this.setupAnswer) },
        finalize: this.isAllQuestionsAnswered,
      },
      handlePayloadErrors: false,
      done: () => {
        this.submitting = false
      },
    })

    if (result?.errors) {
      this.addError(`Can't submit response for this survey`)
      return
    }

    if (result.data) {
      const { respondToSurvey } = result.data

      if (respondToSurvey.error) {
        this.surveyCompleted = false
        this.submitFailed = true
      } else {
        this.surveyAverageScore = Math.round(respondToSurvey.publicResponse?.averageScore || 0)
        if (this.surveyAverageScore) {
          this.showAnalytics = true
        }
        this.surveyCompleted = true
        this.submitFailed = false
      }
    }
  }

  get validateForm() {
    const requiredQuestions = this.questions!.filter((q) => q.required).map((x) => x.id)

    const answeredQuestionIds = this.setupAnswer.map((answer) => answer.question_id)

    const unansweredQuestionIds = requiredQuestions.filter(
      (questionId) => !answeredQuestionIds.includes(questionId)
    )
    return unansweredQuestionIds.length > 0
  }
}
